<template>
  <div class="row mb-3">
    <div class="col">
      <h4>
{{ $t("languages.languages") }}
</h4>
    </div>
    <div class="col-6 col-md-4">
      <input
          type="search"
          class="form-control py-0"
          v-model="filter"
          :placeholder="`Search languages..`"
      />
    </div>
  </div>
  <div class="row">
    <div
        v-for="language in languages.filter(
        (e) => e.name.toLowerCase().indexOf(filter) > -1
      )"
        class="col-12 col-md-6 col-xl-4"
        :key="language.id"
    >
      <label class="cstm-check dropdown-item">
        <span class="text-black mx-2 regular-14"
        >{{ language.name }} ({{ language.code }})</span
        >
        <input
            type="checkbox"
            v-model="language.enabled"
            v-on:change="
            changeOption(
              'languages',
              language.code,
              $event.currentTarget.checked
            )
          "
        />
        <span class="checkmark select mx-2"></span>
      </label>
    </div>
  </div>
</template>

<script>
import http from "../../../modules/http";

export default {
  name: "Languages",
  data() {
    return {
      loadAddBtn: false,
      filter: "",
      languages: [],
    };
  },
  mounted() {
    http.fetch("/languages/all").then((data) => {
      this.languages = data;
    });
  },
  methods: {
    changeOption(type, id, enable, row = "code") {
      const method = enable ? "enable" : "disable";
      let data = {};
      data[row] = id;
      http.fetch(`/${type}/${method}`, data, true);
    },
  },
};
</script>
